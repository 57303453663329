import React, { forwardRef, useEffect, useRef, useState } from "react";
import Typed from "react-typed";
import CountUp from "react-countup";
import { PopupButton } from "react-calendly";

const Home = forwardRef(function Home({ handleClick }, refHome) {
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  
  return (
    <div
      className="page"
      ref={refHome}
    >
      <div>
        <h2 className="md:text-6xl text-4xl font-bold md:py-3">Hi</h2>
        <h2 className="md:text-5xl text-3xl font-bold md:py-3 py-2">
          My name is
        </h2>
        <h2 className="md:text-5xl tracking-wide sm:text-4xl text-3xl font-semibold md:py-3 py-2">
          Martin Mircea
        </h2>
      </div>

      <div className="flex flex-col w-full">
        <div className="flex items-center pt-6 md:pt-0 w-max">
          <Typed
            className="md:text-5xl sm:text-4xl text-3xl font-bold text-[#00df9a]"
            strings={["Android", "Frontend", "Java", "Web"]}
            typeSpeed={120}
            backSpeed={200}
            loop
          />
          <p className="md:text-5xl sm:text-4xl text-3xl font-bold py-1 px-2">
            Developer
          </p>
        </div>
        <div className="flex">
          <CountUp
            start={0}
            end={47}
            duration={3.8}
            suffix="+"
            className="md:text-4xl sm:text-3xl text-3xl font-bold py-3"
          />
          <p className="md:text-4xl sm:text-3xl text-3xl font-bold py-3">
            Projects
          </p>
        </div>
        <div className="flex flex-col w-min py-6">
          <button
            className="bg-[#00df9a] w-[200px] md:w-[250px] md:mx-0 mx-auto rounded-md font-medium px-6 py-3 my-3 text-black"
            onClick={handleClick}
          >
            Go to my portofolio
          </button>

          <p className="md:text-3xl text-2xl font bold text-white md:py-3 py-1 mx-auto">
            OR
          </p>
          <button className="bg-[#00df9a] w-[200px] md:w-[250px] md:mx-0 mx-auto rounded-md px-6 py-3 my-3 text-black">
            <PopupButton
              url="https://calendly.com/mirceamartinioan"
              rootElement={document.getElementById("root")}
              className="bg-[#00df9a] rounded-md font-medium text-black"
              text="Schedule a meeting"
            />
          </button>
        </div>
      </div>
    </div>
  );
});

export default Home;
