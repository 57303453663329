import React, { useState, forwardRef } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";

const Navbar = forwardRef(function NavBar(
  {
    currentPage,
    scrollHome,
    scrollAbout,
    scrollProjects,
    scrollCertificates,
    scrollContact,
  },
  navRef
) {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  const navHome = () => {
    setNav(!nav);
    scrollHome();
  };

  const navAbout = () => {
    setNav(!nav);
    scrollAbout();
  };

  const navProjects = () => {
    setNav(!nav);
    scrollProjects();
  };

  const navCertificates = () => {
    setNav(!nav);
    scrollCertificates();
  };

  const navContact = () => {
    setNav(!nav);
    scrollContact();
  };

  console.log(currentPage);

  return (
    <div className="z-10 absolute mt-0 flex justify-between items-center h-16 w-full mx-auto px-4 text-white bg-[#3f3f46]">
      <h1 className="w-full text-3xl font-bold text-[#00df9a]">
        Martin Mircea
      </h1>
      <ul className="hidden md:flex">
        {currentPage == 1 ? (
          <li className="navItem-active">
            <button onClick={scrollHome}>Home</button>
          </li>
        ) : (
          <li className="navItem">
            <button onClick={scrollHome}>Home</button>
          </li>
        )}
        {currentPage == 2 ? (
          <li className="navItem-active">
            <button onClick={scrollAbout}>About</button>
          </li>
        ) : (
          <li className="navItem">
            <button onClick={scrollAbout}>About</button>
          </li>
        )}
        {currentPage == 3 ? (
          <li className="navItem-active">
            <button onClick={scrollProjects}>Projects</button>
          </li>
        ) : (
          <li className="navItem">
            <button onClick={scrollProjects}>Projects</button>
          </li>
        )}
        {currentPage == 4 ? (
          <li className="navItem-active">
            <button onClick={scrollCertificates}>Certificates</button>
          </li>
        ) : (
          <li className="navItem">
            <button onClick={scrollCertificates}>Certificates</button>
          </li>
        )}
        {currentPage == 5 ? (
          <li className="navItem-active">
            <button onClick={scrollContact}>Contact</button>
          </li>
        ) : (
          <li className="navItem">
            <button onClick={scrollContact}>Contact</button>
          </li>
        )}
      </ul>

      <div onClick={handleNav} className="block md:hidden z-20">
        {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>

      <div
        className={
          nav
            ? "fixed left-0 bottom-0 w-full h-screen bg-[#121212] text-4xl ease-in-out duration-500 flex flex-col place-content-center gap-10"
            : "fixed left-[-100%] left-0 bottom-0 w-[60%] h-screen ease-in-out duration-500 bg-[#121212] text-4xl flex flex-col place-content-center gap-10"
        }
      >
        <button onClick={navHome}>Home</button>
        <button onClick={navAbout}>About</button>
        <button onClick={navProjects}>Projects</button>
        <button onClick={navCertificates}>Certificates</button>
        <button onClick={navContact}>Contact</button>
      </div>
    </div>
  );
});

export default Navbar;
