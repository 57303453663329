import { forwardRef } from "react";
import React, { useRef, useEffect, useState } from "react";
import CertificateCard from "./CertificateCard";
import app from "../firebase/firebase";
import { getDatabase, ref, onValue } from "firebase/database";
import icon from "../assets/laptop.jpg";
import Loading from "./Loading";

const Certificates = forwardRef(function Certificates(props, refCertificates) {
  const containerRef = useRef(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    // Initialize Realtime Database and get a reference to the service
    const db = getDatabase(app);
    const project = ref(db, "certificates/");

    onValue(project, (snapshot) => {
      const fetchedData = snapshot.val();
      setData(fetchedData); // Update the state with fetched data
    });
  }, []); // Empty dependency array ensures this effect runs only once on component mount

  return (
    <div
      className="page"
    >
      <div>
        <h1
          className="text-center text-5xl font-bold text-white md:pb-20"
          ref={refCertificates}
        >
          Certificates
        </h1>
        <div className="flex overflow-x-auto w-full h-max custom-scrollbar overflow-y-hidden shrink-0">
          {data === null ? (
            <div>
              <Loading />
            </div>
          ) : (
            data.map((project) => (
              <CertificateCard
                title={project.title}
                imgSrc={project.certificate_path}
              />
            ))
          )}
        </div>
      </div>
      
    </div>
  );
});

export default Certificates;
