import { initializeApp } from 'firebase/app';
import 'firebase/database'
// Import the database module if you're using the Realtime Database

const firebaseConfig = {
    apiKey: "AIzaSyBVzXXu8_NiYk7tKtAxq_gj4cAW-1Ir6Yw",
    authDomain: "landing-page-32e56.firebaseapp.com",
    databaseURL:
      "https://landing-page-32e56-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "landing-page-32e56",
    storageBucket: "landing-page-32e56.appspot.com",
    messagingSenderId: "474995939313",
    appId: "1:474995939313:web:64e47e82b44935a9a65650",
  };

  const app = initializeApp(firebaseConfig);

export default app;