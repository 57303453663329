import React, { useState, useMemo, useEffect, useRef } from "react";
import Home from "./components/Home";
import Projects from "./components/Projects";
import Certificates from "./components/Certificates";
import About from "./components/About";
import Contact from "./components/Contact";
import Navbar from "./components/Navbar";

function App() {
  let currentPage = -1;
  const refProjects = useRef(null);
  const refHome = useRef(null);
  const refContact = useRef(null);
  const refCertificates = useRef(null);
  const refAbout = useRef(null);
  const refNav = useRef(null);

  if (useIsInViewport(refHome)) {
    currentPage = 1;
  }
  if (useIsInViewport(refAbout)) {
    currentPage = 2;
  }
  if (useIsInViewport(refProjects)) {
    currentPage = 3;
  }
  if (useIsInViewport(refCertificates)) {
    currentPage = 4;
  }
  if (useIsInViewport(refContact)) {
    currentPage = 5;
  }

  function scrollToHome() {
    refHome.current.scrollIntoView({ behavior: "smooth" });
  }
  function scrollToAbout() {
    refAbout.current.scrollIntoView({ behavior: "smooth" });
  }
  function scrollToprojects() {
    refProjects.current.scrollIntoView({ behavior: "smooth" });
  }
  function scrollToCertificates() {
    refCertificates.current.scrollIntoView({ behavior: "smooth" });
  }
  function scrollToContact() {
    refContact.current.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <div className="h-screen">
      <Navbar
        currentPage={currentPage}
        scrollHome={scrollToHome}
        scrollAbout={scrollToAbout}
        scrollProjects={scrollToprojects}
        scrollCertificates={scrollToCertificates}
        scrollContact={scrollToContact}
      />
      <div className=" fixed bottom-0  w-full h-[calc(100vh-4rem)] snap-y snap-mandatory scroll-smooth overflow-y-scroll">
        <Home handleClick={scrollToprojects} ref={refHome} />
        <About ref={refAbout} />
        <Projects ref={refProjects} />
        <Certificates ref={refCertificates} />
        <Contact ref={refContact} />
      </div>
    </div>
  );
}

function useIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting)
      ),
    []
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}

export default App;
