import { forwardRef } from "react";
import React, { useRef, useEffect, useState } from "react";
import app from "../firebase/firebase";
import Loading from "./Loading";
import { getDatabase, ref, onValue } from "firebase/database";

const About = forwardRef(function About(props, refAbout) {
  const [data, setData] = useState(null);
  useEffect(() => {
    // Initialize Realtime Database and get a reference to the service
    const db = getDatabase(app);
    const project = ref(db, "about/");

    onValue(project, (snapshot) => {
      const fetchedData = snapshot.val();
      setData(fetchedData); // Update the state with fetched data
    });
  }, []);
  return (
    <div
      className="page"
    >
      <h1
        className="mx-auto md:text-5xl text-3xl text-white font-semibold md:pb-5 pb-1"
        ref={refAbout}
      >
        About Me
      </h1>
      <div className=" md:gap-4 gap-3 md:mx-5 flex flex-col p-1 text-white font-semibold">
        {data === null ? (
          <div>
            <Loading />
          </div>
        ) : (
          data.map((p) => <p className="text-m md:text-2xl">{p}</p>)
        )}
      </div>
    </div>
  );
});

export default About;
