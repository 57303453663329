import React, { useRef, useEffect, useState } from "react";
import { forwardRef } from "react";
import { FaPhone, FaEnvelope, FaHandshake, FaLinkedin } from "react-icons/fa";
import { PopupButton } from "react-calendly";
import ReactCountryFlag from "react-country-flag";

const Contact = forwardRef(function Contact({}, refContact) {
  return (
    <div
      className="page"
    >
      <h1
        className="mx-auto text-5xl text-white font-semibold"
        ref={refContact}
      >
        Contact
      </h1>
      <div className="flex flex-col py-5">
        <a href="tel:+436889213814" className="contactHolder">
          <FaPhone
            size={42}
            className="my-auto rotate-90 bg-green-500 rounded p-1 ml-5"
          />
          <div className="mx-5">
            <div className="flex my-auto align-middle place-content-evenly">
              <h3>Phone (AT)</h3>
              <ReactCountryFlag countryCode="AT" svg className="my-auto" />
            </div>

            <p>+43 688 921 3814</p>
          </div>
        </a>
        <a href="tel:+4072884593" className="contactHolder">
          <FaPhone
            size={42}
            className="my-auto rotate-90 bg-green-500 rounded p-1 ml-5"
          />
          <div className="mx-5">
            <div className="flex my-auto align-middle place-content-evenly">
              <h3>Phone (RO)</h3>
              <ReactCountryFlag countryCode="RO" svg className="my-auto" />
            </div>
            <p>+40 742 884 593</p>
          </div>
        </a>

        <a
          href="https://calendly.com/mirceamartinioan"
          className="contactHolder"
        >
          <FaHandshake
            size={42}
            className="my-auto bg-blue-500 rounded p-1 ml-5"
          />
          <div className="mx-5">
            <PopupButton
              url="https://calendly.com/mirceamartinioan"
              /*
               * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
               * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
               */
              rootElement={document.getElementById("root")}
              text="Schedule a call/meeting"
            />
            <p>on Calendly</p>
          </div>
        </a>
        <a href="https://www.linkedin.com/in/mirchm" className="contactHolder">
          <FaLinkedin
            size={42}
            className="my-auto bg-blue-600 rounded p-1 ml-5"
          />
          <div className="mx-5">
            <p>Message me on</p>
            <p>linkedin.com/in/mirchm</p>
          </div>
        </a>
        <a href="mailto:mirceamartinioan@gmail.com" className="contactHolder">
          <FaEnvelope
            size={42}
            className="my-auto bg-red-500 rounded p-1 ml-5"
          />
          <div className="mx-5">
            <h3>Email</h3>
            <p>mirceamartinioan@gmail.com</p>
          </div>
        </a>
      </div>
    </div>
  );
});

export default Contact;
