import { forwardRef } from "react";
import React, { useRef, useEffect, useState } from "react";
import ProjectCard from "./ProjectCard";
import app from "../firebase/firebase";
import { getDatabase, ref, onValue } from "firebase/database";
import icon from "../assets/laptop.jpg";
import Loading from "./Loading";
import { FaGithubSquare } from "react-icons/fa";

const Projects = forwardRef(function Projects(props, refProjects) {
  const containerRef = useRef(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    // Initialize Realtime Database and get a reference to the service
    const db = getDatabase(app);
    const project = ref(db, "projects/");

    onValue(project, (snapshot) => {
      const fetchedData = snapshot.val();
      setData(fetchedData); // Update the state with fetched data
    });
  }, []); // Empty dependency array ensures this effect runs only once on component mount

  return (
    <div
      className="page"
    >
      <h1
        className="mx-auto md:text-5xl text-5xl font-semibold text-white md:pb-20"
        ref={refProjects}
      >
        Projects
      </h1>
      <div
        className="flex overflow-x-auto w-full h-max custom-scrollbar 
      overflow-y-hidden shrink-0"
      >
        {data === null ? (
          <div>
            <Loading />
          </div>
        ) : (
          data.map((project) => (
            <ProjectCard
              key={project.id}
              imgSrc={project.icon_path}
              title={project.title}
              body={project.body}
              playStoreLink={project.play_store_link}
              githubLink={project.github_link}
            />
          ))
        )}
      </div>
      <a
        href="https://github.com/MirchM"
        className="mx-auto flex md:text-xl text-l justify-center align-middle 
        hover:scale-105 ease-in-out duration-300 bg-[#00df9a] rounded-lg p-2 mt-2"
      >
        <h1 className="mx-3 my-auto text-black">See other projects on </h1>
        <FaGithubSquare size={50} className="fill-black" />
      </a>
    </div>
  );
});

export default Projects;
