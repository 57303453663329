import React from "react";
import { FaGithub, FaGooglePlay } from "react-icons/fa";

const ProjectCard = ({ title, body, imgSrc, playStoreLink, githubLink }) => {
  return (
    <div className="rounded-lg bg-[#3f3f46] w-80 h-96 p-2 my-2 mx-1 justify-center align-top flex flex-col shrink-0">
      {imgSrc === undefined ? (
        <div className="w-20 h-16 mx-auto object-contain rounded-lg"></div>
      ) : (
        <img
          className="w-20 h-16 mx-auto object-contain rounded-lg"
          src={imgSrc}
          alt="Could not load image "
        />
      )}

      <p className="text-2xl mx-auto py-2 text-white font-bold overflow-ellipsis">{title}</p>
      <p className="text-xl text-start text-white font-semibold mx-auto my-auto">
        {body}
      </p>
      {playStoreLink ? (
        <a
          href={playStoreLink}
          className="flex mx-auto p-2 bg-black rounded-lg hover:scale-110 ease-in-out duration-300"
        >
          <FaGooglePlay size={20} className="my-auto" />
          <p className="mx-1 font-semibold">Play Store</p>
        </a>
      ) : (
        <></>
      )}
      {githubLink ? (
        <a
          href={githubLink}
          className="flex mx-auto p-2 bg-black rounded-lg hover:scale-110 ease-in-out duration-300"
        >
          <FaGithub size={20} className="my-auto" />
          <p className="mx-1 font-semibold">Github</p>
        </a>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ProjectCard;
