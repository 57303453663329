import React from "react";

const CertificateCard = ({ title, body, imgSrc }) => {
  return (
    <div className="rounded bg-[#3f3f46] w-80 h-96 mx-1 my-3 flex flex-col justify-start grid-rows-1 shrink-0">
      {imgSrc === undefined ? (
        <div className="w-full mx-auto"></div>
      ) : (
        <img className="rounded w-full object-scale-down" src={imgSrc} alt="Could not load" />
      )}
      <p className="text-3xl mx-auto font-semibold my-auto self-end text-center text-white">{title}</p>
    </div>
  );
};

export default CertificateCard;